@import "./variables/Colors";


.primary-color{
  color: $base-color;
}

.primary-bg{
  background-color: $base-color !important;
  background: $base-color !important;
}


.paper {
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background: white;
  border-radius: 10px;
}


.form-modal {
  width: 50% !important;
  margin: 12vh auto 12vh !important;
  background: white;
  border-radius: 10px;
  //box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important
}

.error-box{
  box-sizing: content-box;
  border: 2px solid red;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 1rem;
  background: rgba(crimson,0.1);
  color: crimson;
}


.waring-box{
  box-sizing: content-box;
  border: 2px solid darkorange;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 1rem;
  background: rgba(orange,0.1);
  color: darkorange;
}


@media (max-width: 695px) {
  .Paper {
    padding: 1rem;
  }

  .form-modal {
    margin-top: 8vh !important;
    width: 90% !important;
  }
}

